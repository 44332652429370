import React from "react";
import Header from "./components/organisms/Header";
import Main from "./components/organisms/Main";
import { useParams } from "react-router";




const App = (props) => 
{
  let { token } = useParams();

return (
  <div>
     
    <Header token={token}/>
    <Main />

   
  </div>
)};

export default App;
