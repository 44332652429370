import React from "react";
import "./TokenFailPage.css";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Container
} from "reactstrap";

const TokenFailPage = () => {
 

  return (
    <Container>
           
      <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">Your are not authorized!</h4>
      <p>Please contact Hafnia .....</p>
  
</div>
      
    </Container>
  );
};


export default TokenFailPage;
