import React from "react";


import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  Container
} from "reactstrap";

const CompletedPage = () => {
 

  return (
    <Container>
           
      <div className="alert alert-success" role="alert">
      <h4 className="alert-heading">Form Completed</h4>
      <p>Form has been send to Hafnia for further management.</p>
  
</div>
      
    </Container>
  );
};


export default CompletedPage;
