import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  getAccount,
  rejectFinance,
  approveFinance,
  resubmitFinance,
  sendToMDM,
} from "../../../utils/queries";

import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Label,
  FormGroup,
} from "reactstrap";
import { ResubmitModal } from "./ResubmitModal";
import Alerts from "../../molecules/Alerts";
import v4 from "uuid";
import { RejectModal } from "./RejectModal";
import { ApproveModal } from "./ApproveModal";
import { SendToMDMModal } from "./SendToMDMModal";
import { trackPromise } from "react-promise-tracker";
import { authProvider } from "../../../authProvider";
import { FilesForm } from "./Forms/FilesForm";

const isNil = (value) => {
  return value === undefined || value === null || value === "" || value === " ";
};

export const AccountModal = (props) => {
  const toggle = () => {
    props.setToggle(!props.toggle);
  };

  const userAccount = authProvider.account;

  const [account, setAccount] = useState(null);
  const [accountTypes, setAccountTypes] = useState("");
  const [banks, setBanks] = useState(null);
  const [files, setFiles] = useState(null);
  const [toggleResubmitModal, setToggleResubmitModal] = useState(false);
  const [toggleRejectModal, setToggleRejectModal] = useState(false);
  const [toggleApproveModal, setToggleApproveModal] = useState(false);
  const [toggleSendToMDMModal, setToggleSendToMDMModal] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [fileList, setFileList] = useState([]);

  const onResubmit = async (accountId, additionalEmailText) => {
    try {
      props.setShowLoader(true);
      var account = await resubmitFinance(accountId, additionalEmailText);

      setAccount(account.data);
      props.onUpdateAccount(account.data);

      addAlert("Successfully resubmitted the request");
    } catch (error) {
      addAlert(error, "danger");
    }
    props.setShowLoader(false);
  };

  const onReject = async (accountId, rejectReason) => {
    try {
      props.setShowLoader(true);
      let account = await rejectFinance(accountId, rejectReason);

      setAccount(account.data);
      props.onUpdateAccount(account.data);

      addAlert("Account rejected successfully", "success");
    } catch (error) {
      addAlert(error, "danger");
    }
    props.setShowLoader(false);
  };

  const onApprove = async (accountId, shortname) => {
    try {
      props.setShowLoader(true);
      let result = await approveFinance(accountId, shortname);
      setAccount(result.data);
      props.onUpdateAccount(result.data);

      addAlert("Account approved successfully", "success");
    } catch (error) {
      const data = error.response.data;
      if ("shortname" in data) {
        return data.shortname;
      }
      addAlert(error, "danger");
    } finally {
      props.setShowLoader(false);
    }
    return null;
  };

  const onSendToMDM = async (accountId, shortname) => {
    try {
      props.setShowLoader(true);

      let result = await sendToMDM(accountId, shortname);

      setAccount(result.data);
      props.onUpdateAccount(result.data);

      addAlert("Account has been send to MDM successfully", "success");
    } catch (error) {
      const data = error.response.data;
      if ("shortname" in data) {
        return data.shortname;
      }
      addAlert(error, "danger");
    } finally {
      props.setShowLoader(false);
    }
    return null;
  };

  const addAlert = (message, color) => {
    let id = v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const showAccountModal = async () => {
    try {
      const result = await trackPromise(getAccount(props.id));
      setAccount(result.data.account);
      setAccountTypes(result.data.accountTypes);
      setBanks(result.data.bankDataList);
      setFiles(result.data.files);
      checkRequesterAndLineManager(result.data.account);
    } catch (error) {
      props.addAlert(error, "danger");
    }
  };

  const approvalEnabled = () => {
    return account.status === "Approved by line manager";
  };

  const approvedByFinance = () => account.status === "Approved by finance";

  const checkRequesterAndLineManager = (account) => {
    if (userAccount !== null) {
      const isLineManager =
        userAccount?.userName === account.lineManagerToNotify;
      const isRequester = userAccount?.userName === account.requestedByEmail;
      if (isLineManager && isRequester) {
        addAlert(
          "Notice on this account you are a requester or have approved as line manager!",
          "warning"
        );
      }
    }
  };

  const isNewAccount = () => account.accountId === null;
  const showApproveButton = () => {
    if (props.approveConfig === 1) return true;
    return false;
  };

  const handleSendToMDM = () => {
    const splitValue = account.imoSuffix.split(",");
    let hasImoType = [];
    if (splitValue) {
      splitValue.forEach((i) =>
        isNil(i) ? hasImoType.push(false) : hasImoType.push(true)
      );
      if (hasImoType.includes(true) && account.shortname === null) {
        return setToggleSendToMDMModal(true);
      } else {
        return onSendToMDM(account?.id);
      }
    }
  };

  useEffect(() => {
    async function run() {
      await showAccountModal().then();
      toggle();
    }

    run().then();
  }, [props.id]);

  return account ? (
    <div>
      <Modal isOpen={props.toggle} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Account Info (ID: {account.id})
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Alerts alerts={alerts} setAlerts={setAlerts} />
            </Col>
          </Row>
          <div style={{ marginBottom: 10 }}>
            <Button
              onClick={() => {
                if (
                  isNil(account.imoSuffix) &&
                  account.financeRequireShortname === true
                ) {
                  return onApprove(account?.id);
                } else {
                  setToggleApproveModal(true);
                }
              }}
              color="success"
              className={""}
              style={{ marginRight: 10 }}
              disabled={
                !isNewAccount() || !approvalEnabled() || showApproveButton()
              }
              // disabled={true}
            >
              Approve
            </Button>
            <Button
              onClick={() => setToggleRejectModal(true)}
              color="danger"
              style={{ marginRight: 10 }}
              disabled={!approvalEnabled()}
            >
              Reject
            </Button>
            <Button
              onClick={() => setToggleResubmitModal(true)}
              color="warning"
              style={{ marginRight: 10 }}
              disabled={!approvalEnabled()}
            >
              Resubmit
            </Button>
            <Button
              onClick={handleSendToMDM}
              color="info"
              // disabled={
              //   (!approvalEnabled() && isNewAccount()) || approvedByFinance()
              // }
              disabled={!approvalEnabled()}
            >
              Send to MDM
            </Button>
          </div>
          <div>
            <Table bordered striped>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Id</th>
                  <td>{account?.id}</td>
                </tr>
                <tr>
                  <th scope="row">Request contact email</th>
                  <td>{account?.requestContactEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Justification</th>
                  <td>{account?.businessJustification}</td>
                </tr>
                <tr>
                  <th scope="row">Vendor name</th>
                  <td>{account?.name}</td>
                </tr>
                <tr>
                  <th scope="row">Sales contact name</th>
                  <td>{account?.salesContactName}</td>
                </tr>
                <tr>
                  <th scope="row">Sales contact mobile</th>
                  <td>{account?.salesContactMobile}</td>
                </tr>
                <tr>
                  <th scope="row">Existing account</th>
                  <td>{account?.accountName}</td>
                </tr>
                <tr>
                  <th scope="row">Account types</th>
                  <td>{accountTypes}</td>
                </tr>
                <tr>
                  <th scope="row">Short Name</th>
                  <td>{account?.shortname}</td>
                </tr>
                <tr>
                  <th scope="row">Submitted Date</th>
                  <td>
                    {account?.formSubmittedDate == null
                      ? ""
                      : moment(account?.formSubmittedDate).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Address 1</th>
                  <td>{account?.address1}</td>
                </tr>
                <tr>
                  <th scope="row">Address 2</th>
                  <td>{account?.address2}</td>
                </tr>
                <tr>
                  <th scope="row">City</th>
                  <td>{account?.city}</td>
                </tr>
                <tr>
                  <th scope="row">Zip</th>
                  <td>{account?.zip}</td>
                </tr>
                <tr>
                  <th scope="row">Country</th>
                  <td>{account?.country?.country}</td>
                </tr>
                <tr>
                  <th scope="row">Phone</th>
                  <td>{account?.phone}</td>
                </tr>
                <tr>
                  <th scope="row">Sales email</th>
                  <td>{account?.salesEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Finance email</th>
                  <td>{account?.financeEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Payment term</th>
                  <td>{account?.paymentTermName}</td>
                </tr>
                <tr>
                  <th scope="row">Tax registration number</th>
                  <td>{account?.taxRegistrationNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Notes</th>
                  <td>{account?.notes}</td>
                </tr>
                <tr>
                  <th scope="row">Requested by name</th>
                  <td>{account?.requestedByName}</td>
                </tr>
                <tr>
                  <th scope="row">Requested by email</th>
                  <td>{account?.requestedByEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Finance approved by</th>
                  <td>{account?.approvedByFinanceName}</td>
                </tr>
                <tr>
                  <th scope="row">Approved by finance at</th>
                  <td>
                    {" "}
                    {account?.approvedByFinanceAt == null
                      ? ""
                      : moment(account?.approvedByFinanceAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Line manager approved by</th>
                  <td>{account?.approvedByLineManagerName}</td>
                </tr>
                <tr>
                  <th scope="row">Approved by Line manager at</th>
                  <td>
                    {" "}
                    {account?.approvedByLineManagerAt == null
                      ? ""
                      : moment(account?.approvedByLineManagerAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Finance rejected by</th>
                  <td>{account?.rejectedByFinanceName}</td>
                </tr>
                <tr>
                  <th scope="row">Rejected by finance at</th>
                  <td>
                    {" "}
                    {account?.rejectedByFinanceAt == null
                      ? ""
                      : moment(account?.rejectedByFinanceAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Line manager rejected by</th>
                  <td>{account?.rejectedByLineManagerName}</td>
                </tr>
                <tr>
                  <th scope="row">Rejected by Line manager at</th>
                  <td>
                    {" "}
                    {account?.rejectedByLineManagerAt == null
                      ? ""
                      : moment(account?.rejectedByLineManagerAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Line manager to notify</th>
                  <td>{account?.lineManagerToNotify}</td>
                </tr>
                <tr>
                  <th scope="row">Finance approver to notify</th>
                  <td>{account?.financeApproverToNotify}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          {account?.isGiro ? (
            <GIRO account={account} />
          ) : (
            <BANK vendorName={account.name} bankDataList={banks} />
          )}

          <h4>Files</h4>
          <div>
            {files?.map((file, index) => (
              <div key={index}>
                <a target="_blank" rel="noopener noreferrer" href={file.url}>
                  {file.source || "Vendor Form"} | {file.displayName} |{" "}
                  {moment(file?.inserted).format("DD-MM-YYYY HH:mm")}
                </a>
              </div>
            ))}
          </div>
          <h4 style={{ marginTop: "10px" }}>Add File Attachment</h4>
          <FormGroup>
            <Label>Attach doc as additional business justification.</Label>
            <FilesForm
              bankDataId={0}
              accountId={account.id}
              name="mainFileForm"
              fileList={fileList}
              setFileList={setFileList}
              token={account.token}
            ></FilesForm>
          </FormGroup>
        </ModalBody>
      </Modal>
      <ResubmitModal
        addAlert={addAlert}
        setToggle={setToggleResubmitModal}
        toggle={toggleResubmitModal}
        onResubmit={onResubmit}
        accountId={props.id}
      />
      <RejectModal
        addAlert={addAlert}
        setToggle={setToggleRejectModal}
        toggle={toggleRejectModal}
        onReject={onReject}
        accountId={props.id}
      />
      <ApproveModal
        addAlert={addAlert}
        setToggle={setToggleApproveModal}
        toggle={toggleApproveModal}
        onSubmit={onApprove}
        accountId={props.id}
      />
      <SendToMDMModal
        addAlert={addAlert}
        setToggle={setToggleSendToMDMModal}
        toggle={toggleSendToMDMModal}
        onSubmit={onSendToMDM}
        accountId={props.id}
      />
    </div>
  ) : null;
};

const GIRO = (props) => {
  return (
    <div>
      <h4>GIRO</h4>
      <Table bordered striped>
        <tbody>
          <tr>
            <td style={{ width: 200 }}>Account number</td>
            <td>{props.account.accountNumber}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const BANK = ({ bankDataList, vendorName }) => {
  return (
    <>
      <h4>Bank Data</h4>
      <div>
        {bankDataList?.map((bank, index) => (
          <React.Fragment key={bank.id}>
            {bank.beneficiaryBankAccountName.trim() !== vendorName?.trim() ? (
              <p className="text-danger">
                Vendor name is different from Beneficiary name (usually same as
                Vendor name)
              </p>
            ) : null}
            <Table bordered striped key={index}>
              <tbody>
                <tr>
                  <td scope="row">
                    {" "}
                    Beneficiary name (usually same as Vendor name)
                  </td>
                  <td>{bank.beneficiaryBankAccountName}</td>
                </tr>
                <tr>
                  <td scope="row">Currency</td>
                  <td>{bank.currency.name}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>IBAN</td>
                  <td>{bank.iban}</td>
                </tr>
                <tr>
                  <td scope="row">BIC/SWIFT</td>
                  <td>{bank.bic}</td>
                </tr>
                <tr>
                  <td scope="row">Corresponding BIC/SWIFT</td>
                  <td>{bank.correspondingBIC}</td>
                </tr>
                <tr>
                  <td scope="row">Registration Number</td>
                  <td>{bank.regNo}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>Account number</td>
                  <td>{bank.accountNr}</td>
                </tr>
                <tr>
                  <td scope="row">FIK Kortart</td>
                  <td>{bank.fikKortart}</td>
                </tr>
                <tr>
                  <td scope="row">FIK Kreditor/GIRO</td>
                  <td>{bank.fikNumber}</td>
                </tr>
                <tr>
                  <td scope="row">Bank</td>
                  <td>{bank.bank}</td>
                </tr>
                <tr>
                  <td scope="row">Branch</td>
                  <td>{bank.branch}</td>
                </tr>
                <tr>
                  <td scope="row">Address</td>
                  <td>{bank.address}</td>
                </tr>
                <tr>
                  <td scope="row">City</td>
                  <td>{bank.city}</td>
                </tr>
                <tr>
                  <td scope="row">Zip</td>
                  <td>{bank.zip}</td>
                </tr>
                <tr>
                  <td scope="row">Country</td>
                  <td>{bank.country}</td>
                </tr>
                <tr>
                  <td scope="row">Is primary</td>
                  <td>{bank.isPrimary ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td scope="row">Files</td>
                  <td>
                    {bank.files.map((file, index) => (
                      <div key={index}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.url}
                        >
                          {file.displayName}
                        </a>
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </Table>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
