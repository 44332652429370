import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export const SendToMDMModal = (props) => {
  const [shortname, setShortname] = useState("");
  const [showShortnameError, setShowShortnameError] = useState(false);
  const [shortnameError, setShortnameError] = useState("");
  const toggle = () => {
    props.setToggle(!props.toggle);
  };

  const submitted = async (e) => {
    e.preventDefault();
    let validated = validate();

    if (!validated) {
      return;
    }

    let result = await props.onSubmit(props.accountId, shortname);
    if (result !== null) {
      setShortnameError(result);
      setShowShortnameError(true);
      return;
    }
    toggle();
  };

  const validate = () => {
    let isSuccess = true;
    var length = shortname?.length;
    if (!shortname || length > 30) {
      setShortnameError("Shortname should not exceed 30 characters.");
      setShowShortnameError(true);
      isSuccess = false;
    }

    return isSuccess;
  };
  return (
    <Modal isOpen={props.toggle} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Send to MDM Account</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitted}>
          <FormGroup>
            <Label for="shortnameId">Shortname</Label>
            <Input
              type="edit"
              name="shortnameId"
              id="shortnameId"
              placeholder="Short name"
              onChange={(e) => setShortname(e.target?.value)}
              value={shortname}
            />
            {showShortnameError && (
              <p className="text-danger">{shortnameError}</p>
            )}
          </FormGroup>

          <Button color="success" className="mr-2 mt-3">
            Send to MDM
          </Button>
          <Button className="mt-3" color="danger" onClick={() => toggle()}>
            Close
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
