import axios from "axios";
import config from "../config";

const url = config.API_URL;
const financeUrl = "finance";

const apiGet = async (url) => {
  return axios.get(`${config.API_URL}${url}`);
};

const apiDelete = async (url, token) => {
  return axios.delete(`${config.API_URL}${url}`, {
    headers: { "X-API-KEY": token },
  });
};

const apiPost = async (url, form) => {
  return axios.post(`${config.API_URL}${url}`, form);
};

export const getAllAccounts = async () => {
  const url = `account/all`;
  return apiGet(url);
};

export const getAccount = async (id) => {
  const url = `account/${id}`;
  return apiGet(url);
};

export const rejectFinance = async (id, rejectReason) => {
  const data = {
    VFAccountId: parseInt(id, 10),
    rejectReason: rejectReason,
  };
  return apiPost(`account/rejectfinance/`, data);
};

export const approveFinance = async (id, shortname) => {
  const data = {
    accountId: parseInt(id, 10),
    shortname: shortname,
  };
  return apiPost(`account/approvefinance/`, data);
};

export const sendToMDM = async (id, shortname) => {
  const data = {
    accountId: parseInt(id, 10),
    shortname: shortname,
  };
  return apiPost(`account/SendToMDM/`, data);
};

export const resubmitFinance = async (id, additionalEmailText) => {
  const data = {
    VFAccountId: parseInt(id, 10),
    AdditionalEmail: additionalEmailText,
  };
  return apiPost(`account/resubmit`, data);
};

export const apiDeleteFile = async (token, fileName) => {
  return apiDelete(`${financeUrl}/RemoveFileAttachment/${fileName}`, token);
};

export const apiPostFiles = async (form) => {
  return apiPost(`${financeUrl}/AddFileAttachment`, form);
};
