import { MsalAuthProvider, LoginType } from "react-aad-msal";

var redirectUri = "http://localhost:4040";
var clientId = "ebbd6848-d7e3-43e1-9494-b0e60164af5a";

if (process.env.REACT_APP_STAGE === "Dev") {
  redirectUri = "https://app-accounts-finance-dev.azurewebsites.net";
  clientId = "ebbd6848-d7e3-43e1-9494-b0e60164af5a";
}
if (process.env.REACT_APP_STAGE === "Test") {
  redirectUri = "https://app-accounts-finance-tst.azurewebsites.net";
  clientId = "ebbd6848-d7e3-43e1-9494-b0e60164af5a";
} else if (process.env.REACT_APP_STAGE === "Pre-Production") {
  redirectUri =
    "https://hafniabw-account-approval-finance-pre.azurewebsites.net";
  clientId = "ddfb5d43-7680-4645-a1bb-a336439f36cb";
} else if (process.env.REACT_APP_STAGE === "Production") {
  redirectUri = "https://accounts-finance.hafniabw.com";
  clientId = "ddfb5d43-7680-4645-a1bb-a336439f36cb";
}

// Portal
const msalConfig = {
  auth: {
    authority:
      "https://login.microsoftonline.com/a364eb28-e95b-4ad0-a4fb-5b4f7767ad84",
    clientId: clientId,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const authParameters = {
  scopes: [
    "api://422132b5-d04c-4e43-a6db-6651f01a1109/Read", // API SCOPE
  ],
};

const authOptions = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  msalConfig,
  authParameters,
  authOptions
);
