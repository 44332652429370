import React,{useState} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";

import { AzureAD } from 'react-aad-msal';
import { authProvider } from './authProvider';
import "./axiosAuthProvider"

const LoadingIndicator = props => {

  const { promiseInProgress } = usePromiseTracker();

  const [toggle, setToggle] = useState(false);

    return (

      <div>
         <Modal isOpen={promiseInProgress} className={"modal-dialog-center"}>
       
        <ModalBody >
        <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
          </div>
        </ModalBody>
      </Modal>
      </div>



      
    );  
   }


ReactDOM.render(
   <BrowserRouter>
   
      <App />
     <LoadingIndicator></LoadingIndicator>  
    
  </BrowserRouter>
  ,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
