import React from "react";

import Loader from "react-spinners/SquareLoader";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  // right: 0;
  // bottom: 0
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.09); /* Black w/ opacity */
  align:center;
  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
  margin-top:50px;
  height:30px;
`;

const LoaderOverlay = (props) => {
  
  // const onDismissAlert = (a) => {
  //   props.setAlerts(props.alerts.filter((item) => item.id !== a.id));
  // };

  return (
    <>
        <DarkBackground disappear={props.show}>
        
        <div>
         <Loader color={'#004976'} loading={true} css={override} size={50} height={100} />
         <p style={{textAlign:'center', fontSize:'32px',color:'#004976'}}>Please wait while we load your data</p>
       </div>
       </DarkBackground> 
    </>
  );
};

export default LoaderOverlay;
