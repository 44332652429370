import React, { useState } from "react";

import Confirm from "../../../molecules/Confirm";
import { trackPromise } from "react-promise-tracker";
import { default as UUID } from "uuid";
import { apiDeleteFile, apiPostFiles } from "../../../../utils/queries";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { Alert, Col, Label, Row, Table } from "reactstrap";

export const FilesForm = (props) => {
  const [toggleConfirmFileDelete, setToggleConfirmFileDelete] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [alerts, setAlerts] = useState([]);

  const onDismissAlert = (a) => {
    setAlerts(alerts.filter((item) => item.id !== a.id));
  };

  const onDeleteFile = async (file) => {
    setSelectedFile(file);
    setToggleConfirmFileDelete(!toggleConfirmFileDelete);
  };

  const selectedFilesChanged = async (e) => {
    let form = new FormData();

    form.append("BankDataId", props.bankDataId);
    form.append("AccountId", props.accountId);
    form.append("Token", props.token);

    console.log(e.target.files);
    console.log(props.fileList);

    for (var index = 0; index < e.target.files.length; index++) {
      var file = e.target.files[index];

      for (var i = 0; i < props.fileList.length; i++) {
        var existingFile = props.fileList[i];

        if (existingFile.name === file.name) {
          addAlert("Files with same name allready added", "danger");
          return;
        }
      }

      form.append("file", file);
    }
    try {
      let files = await trackPromise(apiPostFiles(form));
      props.setFileList([...props.fileList, ...files.data]);
      addAlert(`Files was uploaded`, "success");
    } catch (error) {
      addAlert(
        `Uploading file failed. Please contact Hafnia: ${error}`,
        "danger"
      );
    }
  };

  const onDeleteFileConfirmed = async () => {
    try {
      await apiDeleteFile(props.token, selectedFile.fileName);
      addAlert("File deleted", "success");
      props.setFileList(
        props.fileList.filter((item) => item.fileName !== selectedFile.fileName)
      );
    } catch (error) {
      addAlert("Could not delete file. Please contact Hafnia.", "danger");
    }
  };

  const addAlert = (message, color) => {
    let id = UUID.v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  return (
    <>
      {/* <div>{props.bankDataId}</div> */}
      {/* <Button onClick={test}>Test</Button> */}

      <Alerts alerts={alerts} onDismissAlert={onDismissAlert}></Alerts>

      {/* <div><pre>{JSON.stringify(props.fileList, null, 2) }</pre></div> */}

      <Row>
        <Col>
          <Label for={"file-upload-" + props.name} className={"btn btn-info"}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </Label>
          <input
            id={"file-upload-" + props.name}
            type="file"
            onChange={selectedFilesChanged}
            multiple
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Table borderless striped>
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.fileList
                ?.filter((file) => file.bankDataId === props.bankDataId)
                .map((file, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.displayName}
                      </a>
                    </td>
                    <td className={"text-right iconcell"}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => onDeleteFile(file)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Confirm
        onConfirm={onDeleteFileConfirmed}
        text="Are you sure you want to delete this?"
        toggle={toggleConfirmFileDelete}
        setToggle={setToggleConfirmFileDelete}
      ></Confirm>
    </>
  );
};

const Alerts = (props) => {
  return (
    <>
      {props.alerts.map((alert, index) => (
        <Alert
          key={index}
          color={alert.color}
          toggle={() => props.onDismissAlert(alert)}
        >
          {alert.message}
        </Alert>
      ))}
    </>
  );
};
