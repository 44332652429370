import React from "react";
import { Button } from 'reactstrap';

const Page1 = () => (
  <div className="container">
    <h1>Page1</h1>
    <Button color="danger">Danger!</Button>
  </div>
);

export default Page1;
