import axios from "axios";
import { authProvider } from "./authProvider";
import {environment} from "./config"

axios.interceptors.request.use(
  async (config) => {

    // const backendUrl = new URL(environment.backendUrl);
    // if(!config.url.includes(backendUrl.hostname))
    //   return config;

    const accessTokenResponse = await authProvider.getAccessToken();

    // alert(accessTokenResponse.accessToken);

    config.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);