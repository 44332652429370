import React, { useState, useEffect } from "react";
import { default as UUID } from "uuid";
import Alerts from "../../molecules/Alerts";
import moment from "moment";
import { AccountModal } from "./AccountModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faBell } from "@fortawesome/free-solid-svg-icons";

import { getAllAccounts } from "../../../utils/queries";
import "./HomePage.css";

import { Container, Input, Col, Row, Table } from "reactstrap";

import { Link, Route, useParams, useHistory } from "react-router-dom";
import LoaderOverlay from "../../molecules/Loader";
import { trackPromise } from "react-promise-tracker";

// import Loader from "react-spinners/SquareLoader";
// import styled, { css } from "styled-components";

// const DarkBackground = styled.div`
//   display: none; /* Hidden by default */
//   position: fixed; /* Stay in place */
//   z-index: 999; /* Sit on top */
//   left: 0;
//   top: 0;
//   width: 100%; /* Full width */
//   height: 100%; /* Full height */
//   // right: 0;
//   // bottom: 0
//   overflow: auto; /* Enable scroll if needed */
//   background-color: rgb(0, 0, 0); /* Fallback color */
//   background-color: rgba(0, 0, 0, 0.09); /* Black w/ opacity */
//   align:center;
//   ${props =>
//     props.disappear &&
//     css`
//       display: block; /* show */
//     `}
// `;

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: blue;
//   margin-top:50px;
//   height:30px;
// `;

const Home = (props) => {
  const history = useHistory();
  const [alerts, setAlerts] = useState([]);

  const [nameFilter, setNameFilter] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [accountList, setAccountList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [toggleAccountModal, setToggleAccountModalInternal] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [disableAllApprove, setDisableAllApprove] = useState(0);

  const setToggleAccountModal = (value) => {
    setToggleAccountModalInternal(value);
    if (value === false) history.push("/accounts");
  };

  useEffect(() => {
    loadAccounts().then();
  }, []);

  const addAlert = (message, color) => {
    let id = UUID.v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const searchChanged = (e) => {
    setNameFilter(e.target.value);
  };

  const statusChanged = async (e) => {
    setSelectedStatus(e.target.value);
  };

  const onUpdateAccount = (account) => {
    setAccountList(
      accountList.map((el) => (el.id === account.id ? account : el))
    );
  };

  const loadAccounts = async () => {
    try {
      //setShowLoader(true);
      let result = await trackPromise(getAllAccounts());
      let statusDistinct = result.data.accounts.map((s) => s.status);
      statusDistinct = [...new Set(statusDistinct)];
      statusDistinct = statusDistinct.filter((value) => value !== null);
      statusDistinct = statusDistinct.sort((a, b) => a.localeCompare(b));
      statusDistinct.unshift("Select status");

      setStatusList(statusDistinct);
      setAccountList(result.data.accounts);
      setDisableAllApprove(result.data.disableAllApproval);
    } catch (error) {
      addAlert(error, "danger");
    }
    //setShowLoader(false);
  };

  const filterLogic = (account) => {
    let searchValue = parseInt(nameFilter, 10);
    if (isNaN(searchValue)) {
      return (
        (!nameFilter ||
          account?.name?.toLowerCase().includes(nameFilter.toLowerCase()) ||
          account?.accountName
            ?.toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
          account?.newAccountName
            ?.toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
          account?.token?.toLowerCase() === nameFilter.toLowerCase()) &&
        (!selectedStatus ||
          selectedStatus === "Select status" ||
          account.status === selectedStatus)
      );
    }
    return (
      Object.values(account).some((a) => String(a).includes(searchValue)) &&
      (!selectedStatus ||
        selectedStatus === "Select status" ||
        account.status === selectedStatus)
    );
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Alerts alerts={alerts} setAlerts={setAlerts} />
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col>
            <Input
              type="search"
              name="search"
              id="search"
              placeholder="Enter account name (minimum 2 characters) or account Id"
              onChange={searchChanged}
            />
          </Col>
          <Col>
            <select
              className="form-control"
              name="status"
              onChange={(e) => statusChanged(e)}
            >
              {statusList.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <Table bordered striped>
              <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "8%" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "2%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Vendor bank details verbally confirmed</th>
                  <th>Existing account number</th>
                  <th>Existing account</th>
                  <th>New account name</th>
                  <th>Vendor name</th>
                  <th>Status</th>
                  <th>Form Submitted</th>
                  <th>Requested By</th>
                  <th>Line Manager</th>
                  <th>Finance</th>
                  <th>Finance Notified</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {accountList?.filter(filterLogic).map((account, index) => (
                  <tr key={account.id}>
                    <td>{account.id}</td>
                    <td>{account.verballyConfirmed ? "Yes" : "No"}</td>
                    <td>{account.accountNumber}</td>
                    <td>{account.accountName}</td>
                    <td>{account.newAccountName}</td>
                    <td>{account.name}</td>
                    <td>{account.status}</td>
                    <td>
                      {account.formSubmittedDate == null
                        ? ""
                        : moment(account.formSubmittedDate).format(
                            "DD-MM-YYYY"
                          )}
                    </td>
                    <td>{account.requestedByName}</td>
                    <td>
                      {account.approvedByLineManagerName
                        ? account.approvedByLineManagerName
                        : account.approvedByLineManagerName}
                    </td>
                    <td>
                      {account.approvedByFinanceName
                        ? account.approvedByFinanceName
                        : account.rejectedByFinanceName}
                    </td>
                    <td>{account.financeApproverToNotify}</td>
                    <td style={{ width: 100 }} className={"text-center"}>
                      <Link
                        className="btn btn-sm"
                        to={`${props.match.url}/edit/${account.id}`}
                      >
                        <FontAwesomeIcon icon={faList} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <LoaderOverlay show={showLoader}></LoaderOverlay>

        {/* <DarkBackground disappear={true}>
        
       <div>
        <Loader color={'#004976'} loading={true} css={override} size={50} height={100} />
        <p style={{'text-align':
        'center', fontSize:'32px',color:'#004976'}}>Please wait while we load your data</p>
      </div>
      </DarkBackground>  */}

        <br />

        <Route path={`${props.match.url}/edit/:id`} exact>
          <ShowAccountModal
            // addAlert={addAlert}
            setToggleAccountModal={setToggleAccountModal}
            toggleAccountModal={toggleAccountModal}
            setShowLoader={setShowLoader}
            //onApprove={onApprove}
            onUpdateAccount={onUpdateAccount}
            // onSendToMDM={onSendToMDM}
            showApproveConfig={disableAllApprove}
          />
        </Route>
      </Container>
    </>
  );
};

const ShowAccountModal = ({
  setToggleAccountModal,
  toggleAccountModal,
  addAlert,
  onApprove,
  onReject,
  onUpdateAccount,
  // onSendToMDM,
  setShowLoader,
  showApproveConfig,
}) => {
  const { id } = useParams();

  return (
    <AccountModal
      id={id}
      toggle={toggleAccountModal}
      setToggle={setToggleAccountModal}
      addAlert={addAlert}
      approveConfig={showApproveConfig}
      // onApprove={onApprove}
      // onReject={onReject}
      setShowLoader={setShowLoader}
      onUpdateAccount={onUpdateAccount}
      // onSendToMDM={onSendToMDM}
    />
  );
};

export default Home;
