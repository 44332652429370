import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export const ApproveModal = (props) => {
  const [shortname, setShortname] = useState("");
  const [showShortnameError, setShowShortnameError] = useState(false);
  const [shortnameError, setShortnameError] = useState("");

  const toggle = () => {
    props.setToggle(!props.toggle);
  };

  const submitted = async (e) => {
    e.preventDefault();

    let validated = validate();

    if (!validated) {
      return;
    }
    let result = await props.onSubmit(props.accountId, shortname);
    if (result !== null) {
      setShortnameError(result);
      setShowShortnameError(true);
      return;
    }
    toggle();
  };

  const validate = () => {
    let isSuccess = true;

    if (!shortname) {
      setShowShortnameError(true);
      setShortnameError("Required");
      isSuccess = false;
    }

    return isSuccess;
  };

  return (
    <Modal isOpen={props.toggle} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Approve Account</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitted}>
          <FormGroup>
            <Label for="shortnameId">Short name</Label>
            <Input
              type="edit"
              name="shortnameId"
              id="shortnameId"
              placeholder="Short name"
              onChange={(e) => setShortname(e.target?.value)}
              value={shortname}
            />
            {showShortnameError && (
              <p className="text-danger">{shortnameError}</p>
            )}
          </FormGroup>

          <Button color="success" className="mr-2 mt-3">
            Approve
          </Button>
          <Button className="mt-3" color="danger" onClick={() => toggle()}>
            Close
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
